/* eslint-disable quotes */
import "lightcase/src/js/lightcase.js";
import "select2/dist/js/select2.min.js";
import 'flickity/dist/flickity.pkgd.min.js';
import Flickity from 'flickity';

var shrinkHeader = 5;

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    jQuery(".form-dropdown").select2({
      dropdownParent: $('.select2-wrapper'),
    });

    // jQuery("a[data-rel^=lightcase]").lightcase();

    jQuery("a[data-rel^=lightcase]").lightcase({
      slideshowAutoStart: false,
    });

    $('header .mobile-menu').click(function(){
      $('body').toggleClass('mobile-menu-active');
    });

    cardSectionSlider();

    let $gallerySection = $('.gallery-section .gallery-wrapper');

    if ($gallerySection.length > 0) {

      if (matchMedia('screen and (max-width: 992px)').matches ) {

        var cellAlignValue = ''

        if (matchMedia('screen and (max-width: 768px)').matches ) {
          cellAlignValue = 'center';
        } else {
          cellAlignValue = 'left';
        }

        $gallerySection.flickity({
          contain: false,
          pageDots: false,
          prevNextButtons: true,
          adaptiveHeight: false,
          wrapAround: false,
          cellAlign: cellAlignValue,
          initialIndex: 3,
        });
      }
    }
  },
};

$(window).on('resize', function () {
  cardSectionSlider();
});

$(window).on('load', function () {
  $('.gallery-section .gallery-wrapper').flickity(
    'resize'
  );
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function cardSectionSlider() {

  let $cardSectionSlider = $('.cards-section .cards-wrp');

  if ($(window).width() < 992) {
    if ($cardSectionSlider.length > 0) {
      $cardSectionSlider.flickity({
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true,
      });
    }
 }
}
